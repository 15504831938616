import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/add',
    name: 'add',
    component: () => import('../views/tlias/AddView.vue')
  },
  {
    path: '/list',
    name: 'list',
    component: () => import('../views/tlias/ListView.vue')
  },
  {
    path: '/dept',
    name: 'dept',
    component: () => import('../views/tlias/DeptView.vue')
  },
  {
    path: '/about',
    name: 'about',
    component: () => import('../views/AboutView.vue')
  },
  {
    path: '/',
    redirect: '/dept'
  }
]

const router = new VueRouter({
  routes
})

export default router
